import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-xs);
  padding: var(--spacing-md) var(--spacing-2xl) var(--spacing-2xl);
  border-top: 1px solid var(--border-hairline);
  box-shadow: var(--elevation-2);
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--surface);

  &[data-mobile='true'] {
    padding: var(--spacing-sm) var(--spacing-md);
  }
`;

export const ContainerPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-xs);
  padding: var(--spacing-md) var(--spacing-2xl) var(--spacing-2xl);
  border-top: 1px solid var(--border-hairline);
  opacity: 0;
  pointer-events: none;

  &[data-mobile='true'] {
    padding: var(--spacing-sm) var(--spacing-md);
  }
`;
