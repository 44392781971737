import { EntityType } from '@remento/types/entity';
import { UserNotificationSettings } from '@remento/types/user-notification-settings';

import { useEntity, useEntityByForeignId } from '@/hooks/useQuery.ts';
import { useServices } from '@/Services';

export function usePersonUserQuery(personId: string | null | undefined) {
  const { userService, userCacheService } = useServices();

  return useEntityByForeignId(
    EntityType.USER,
    personId,
    async (id) => {
      const user = await userCacheService.getUserByPersonId(id);
      return user?.id ?? null;
    },
    // The personId will never be null here
    (_, scope) => userService.getUserByPersonId(personId ?? '', scope),
  );
}

export function useNotificationSettingUserQuery(userRefIds: string[] | null) {
  const { userService } = useServices();

  return useEntity(
    EntityType.USER_NOTIFICATION_SETTINGS,
    userRefIds?.[0] ?? null,
    (id, scope) => userService.getNotificationSettingsByUserId(id, scope),
    (_, userNotification: UserNotificationSettings) => userRefIds?.includes(userNotification.userId) ?? false,
  );
}
