import { styled } from '@linaria/react';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;

  body[data-mobile='true'] & {
    padding: 0 var(--spacing-lg);
  }
`;

export const NotificationRowDescriptions = styled.div`
  display: flex;
  flex-flow: column;
  min-width: 1.5rem;
  gap: var(--spacing-xs);
`;

export const NotificationsRows = styled.div`
  display: flex;
  flex-flow: column;
`;

export const NotificationsRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--border-hairline);
  padding: var(--spacing-md) 0;

  :last-child {
    border-bottom: 1px solid var(--border-hairline);
  }

  body[data-mobile='true'] &:first-child {
    border-top: 0;
  }

  body[data-mobile='true'] & {
    flex-flow: column;
    gap: var(--spacing-md);
  }
`;

export const NotificationsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-md) 0;

  body[data-mobile='true'] & {
    display: none;
  }
`;

export const NotificationTypes = styled.div`
  display: flex;

  body[data-mobile='true'] & {
    gap: var(--spacing-lg);
  }
`;

export const NotificationType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.25rem;

  body[data-mobile='true'] & {
    width: auto;
  }
`;
