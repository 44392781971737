import { ReactNode } from 'react';

import { RMGrid } from '../RMGrid';

import { SectionContent } from './RMSettingsSectionContent.styles';

export interface RMSettingsSectionContentProps {
  columns?: number;
  children?: ReactNode;
}

export function RMSettingsSectionContent({ columns = 8, children }: RMSettingsSectionContentProps) {
  return (
    <RMGrid.Item columns={columns}>
      <SectionContent>{children}</SectionContent>
    </RMGrid.Item>
  );
}
