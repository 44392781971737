import { ReactNode } from 'react';

import { RMGrid } from '../RMGrid';

import { SettingsSection } from './RMSettingsSection.styles';

export interface RMSettingsSectionProps {
  children: ReactNode;
}

export function RMSettingsSection({ children }: RMSettingsSectionProps) {
  return (
    <SettingsSection>
      <RMGrid.Root spacing="lg">{children}</RMGrid.Root>
    </SettingsSection>
  );
}
