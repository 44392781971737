import { PropsWithChildren } from 'react';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { Container } from './RMDialogPanelTransition.styles';

export type RMDialogPanelTransitionProps = PropsWithChildren<{
  direction?: 'ltr' | 'rtl';
}>;

export const DialogPanelTransitions = {
  rtl: {
    initial: {
      translateX: '100%',
      opacity: 0,
    },
    animate: {
      translateX: '0%',
      opacity: 1,
      transition: { duration: 0.4, ease: 'easeOut' },
    },
    exit: {
      translateX: '-100%',
      opacity: 0,
      transition: { duration: 0.25, ease: 'easeIn' },
    },
  },
  ltr: {
    initial: {
      translateX: '-100%',
      opacity: 0,
    },
    animate: {
      translateX: '0%',
      opacity: 1,
      transition: { duration: 0.4, ease: 'easeOut' },
    },
    exit: {
      translateX: '100%',
      opacity: 0,
      transition: { duration: 0.25, ease: 'easeIn' },
    },
  },
};

export function RMDialogPanelTransition({ direction = 'rtl', children }: RMDialogPanelTransitionProps) {
  const isMobile = useIsMobileViewport();

  return (
    <Container
      initial={DialogPanelTransitions[direction].initial}
      animate={DialogPanelTransitions[direction].animate}
      exit={DialogPanelTransitions[direction].exit}
      data-mobile={isMobile}
    >
      {children}
    </Container>
  );
}
