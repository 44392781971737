import { RMSettingsSection } from './RMSettingsSection';
import { RMSettingsSectionContent } from './RMSettingsSectionContent';
import { RMSettingsSectionTitle } from './RMSettingsSectionTitle';

export type { RMSettingsSectionProps } from './RMSettingsSection';
export type { RMSettingsSectionContentProps } from './RMSettingsSectionContent';
export type { RMSettingsSectionTitleProps } from './RMSettingsSectionTitle';

export const RMSettings = {
  Section: RMSettingsSection,
  SectionTitle: RMSettingsSectionTitle,
  SectionContent: RMSettingsSectionContent,
} as const;
