import { CSSProperties, PropsWithChildren } from 'react';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { Container } from './RMDialogPanelContent.styles';

export type RMDialogPanelContentProps = PropsWithChildren<{
  style?: CSSProperties;
  className?: string;
}>;

export function RMDialogPanelContent({ style, className, children }: RMDialogPanelContentProps) {
  const isMobile = useIsMobileViewport();

  return (
    <Container data-mobile={isMobile} className={className} style={style}>
      {children}
    </Container>
  );
}
