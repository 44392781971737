import { EmailNotificationSettings, PhoneNotificationSettings } from '@remento/types/user-notification-settings';
import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

export interface NotificationSettingsEditFormInput {
  email: EmailNotificationSettings;
  phone: PhoneNotificationSettings;
}

export type NotificationSettingsEditForm = Form<NotificationSettingsEditFormInput>;

export function createNotificationSettingsEditFormSchema() {
  const notificationSettingsSchema = z.object({
    reminderToAddPrompt: z.boolean(),
    reminderToNudge: z.boolean(),
    reminderToRecord: z.boolean(),
    reminderToReact: z.boolean(),
    storyRecorded: z.boolean(),
    storyRecordedAIHighlightReel: z.boolean(),
    reactionSent: z.boolean(),
    timeToRecord: z.boolean(),
    pollCreated: z.boolean(),
  });

  return z.object({
    email: notificationSettingsSchema,
    phone: notificationSettingsSchema,
  });
}

export function createNotificationSettingsEditForm(
  defaultValues?: DeepPartial<NotificationSettingsEditFormInput>,
): NotificationSettingsEditForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createNotificationSettingsEditFormSchema(),
  });
}
