import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  padding: var(--spacing-2xl) var(--spacing-3xl);
  width: 100%;

  &[data-mobile='true'] {
    padding: var(--spacing-2xl) 0;
  }
`;
