import { ReactNode } from 'react';

import { RMGrid } from '../RMGrid';
import { RMText } from '../RMText/RMText';

export type RMSettingsSectionTitleProps = {
  columns?: number;
} & ({ children: ReactNode } | { title: string });

export function RMSettingsSectionTitle({ columns = 4, ...props }: RMSettingsSectionTitleProps) {
  return (
    <RMGrid.Item columns={columns}>
      {'title' in props ? (
        <RMText type="serif" size="m" color="on-surface-primary">
          {props.title}
        </RMText>
      ) : (
        props.children
      )}
    </RMGrid.Item>
  );
}
