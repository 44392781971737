import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  position: relative;
  display: flex;
  flex-flow: column;
  flex: 1;
  padding: var(--spacing-2xl) var(--spacing-3xl);
  overflow-x: hidden;
  overflow-y: auto;

  &[data-mobile='true'] {
    padding: var(--spacing-2xl) 0;
  }
`;
