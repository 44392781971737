import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 999;
`;

export const Backdrop = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--scrim-strong);
  backdrop-filter: blur(4px);
`;

export const Panel = styled(motion.div)`
  position: absolute;
  top: 0;
  height: 100svh;
  width: 44rem;
  background-color: var(--surface);
  display: flex;
  flex-direction: column;

  &[data-mobile='true'] {
    width: 100vw;
    max-width: none;
  }
`;
