import { PropsWithChildren } from 'react';
import { AnimatePresence } from 'framer-motion';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { Container, ContainerPlaceholder } from './RMDialogPanelActions.styles';

export type RMDialogPanelActionsProps = PropsWithChildren<{
  visible?: boolean;
}>;

export function RMDialogPanelActions({ visible = true, children }: RMDialogPanelActionsProps) {
  const isMobile = useIsMobileViewport();

  return (
    <AnimatePresence initial={false}>
      {visible ? (
        <>
          <Container
            initial={{ translateY: '100%' }}
            animate={{ translateY: '0%', transition: { duration: 0.25, ease: 'easeOut' } }}
            exit={{ translateY: '100%', transition: { duration: 0.25, ease: 'easeIn' } }}
            data-mobile={isMobile}
          >
            {children}
          </Container>
          {/* We need to render the content in a fixed position to avoid scrolling issues */}
          <ContainerPlaceholder data-mobile={isMobile}>{children}</ContainerPlaceholder>
        </>
      ) : null}
    </AnimatePresence>
  );
}
