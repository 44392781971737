import { styled } from '@linaria/react';

export const SettingsSection = styled.div`
  padding: var(--spacing-3xl) 0;
  border-bottom: 1px solid var(--border-hairline);

  &:first-child {
    padding-top: var(--spacing-xs);
  }
`;
