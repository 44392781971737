import { useCallback } from 'react';

import { RMAvatar } from '@/components/RMAvatar/RMAvatar';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMLink } from '@/components/RMLink/RMLink';
import { RMPhoneInput } from '@/components/RMPhoneInput';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer, InputController } from '@/modules/form/container';
import { captureException } from '@/utils/captureException';
import { FilestackHandle, openFilestackPicker } from '@/utils/filestack';

import { ProfileEditForm } from '../../forms/profile-edit.form';

import { Container, Form, Header, PhoneNumberInputContainer } from './ProfileEdit.styles';

interface ProfileEditProps {
  profileNameInitials: string;
  photoUrl?: string | null;
  showEmail: boolean;
  form: ProfileEditForm;
  isStoryteller: boolean;
  onPhotoChange: (file: FilestackHandle) => void;
}

export function ProfileEdit({
  profileNameInitials,
  photoUrl,
  showEmail,
  form,
  isStoryteller,
  onPhotoChange,
}: ProfileEditProps) {
  const isMobile = useIsMobileViewport();

  const handleOpenPhotoPicker = useCallback(async () => {
    try {
      await openFilestackPicker({
        accept: ['image/png', 'image/jpeg', 'image/webp'],
        maxSize: 1024 * 1024 * 20,
        onUploadDone: ({ filesUploaded }) => {
          if (!filesUploaded[0]) {
            return;
          }

          onPhotoChange(filesUploaded[0]);
        },
      });
    } catch (error) {
      captureException(error, true);
    }
  }, [onPhotoChange]);

  return (
    <Container data-mobile={isMobile}>
      <Header>
        <RMAvatar src={photoUrl} placeholder={profileNameInitials} size="large" background="blend" shape="circle" />
        <RMLink onClick={handleOpenPhotoPicker}>Change profile picture</RMLink>
      </Header>

      <Form>
        <InputContainer form={form} path="firstName">
          {(props) => (
            <RMInput id="firstName" label={isStoryteller ? 'Storyteller first name' : 'First name'} {...props} />
          )}
        </InputContainer>

        <InputContainer form={form} path="lastName">
          {(props) => (
            <RMInput id="lastName" label={isStoryteller ? 'Storyteller last name' : 'Last name'} {...props} />
          )}
        </InputContainer>

        {showEmail && (
          <InputContainer form={form} path="email">
            {(props) => <RMInput id="email" label={isStoryteller ? 'Storyteller email' : 'Email'} {...props} />}
          </InputContainer>
        )}

        <InputController form={form} path="phone">
          {(props) => (
            <PhoneNumberInputContainer>
              <RMPhoneInput
                label={isStoryteller ? 'Storyteller phone number' : 'Phone number'}
                dropdownPosition="top"
                {...props}
              />
              <RMText type="sans" size="xxs" color="on-surface-tertiary">
                Remento sends helpful reminders via text (currently US numbers only). Text STOP or disable in settings
                to unsubscribe. Msg & data rates may apply.
              </RMText>
            </PhoneNumberInputContainer>
          )}
        </InputController>
      </Form>
    </Container>
  );
}
