import { RMDialogPanel as Root } from './RMDialogPanel';
import { RMDialogPanelActions as Actions } from './RMDialogPanelActions';
import { RMDialogPanelContent as Content } from './RMDialogPanelContent';
import { RMDialogPanelLoader as Loader } from './RMDialogPanelLoader';
import { RMDialogPanelTitle as Title } from './RMDialogPanelTitle';
import { RMDialogPanelTransition as Transition } from './RMDialogPanelTransition';

export const RMDialogPanel = {
  Root,
  Title,
  Content,
  Actions,
  Transition,
  Loader,
} as const;

export type { RMDialogPanelProps } from './RMDialogPanel';
export type { RMDialogPanelActionsProps } from './RMDialogPanelActions';
export type { RMDialogPanelContentProps } from './RMDialogPanelContent';
export type { RMDialogPanelTitleProps } from './RMDialogPanelTitle';
export type { RMDialogPanelTransitionProps } from './RMDialogPanelTransition';
