import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { AnimatePresence } from 'framer-motion';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { Backdrop, Panel, Root } from './RMDialogPanel.styles';

export type RMDialogPanelProps = PropsWithChildren<{
  open: boolean;
  position?: 'left' | 'right';
  onClose: () => void;
}>;

const panelTransitions = {
  right: {
    initial: { right: '-100%' },
    animate: { right: '0%' },
    exit: { right: '-100%' },
  },
  left: {
    initial: { left: '-100%' },
    animate: { left: '0%' },
    exit: { left: '-100%' },
  },
};

export function RMDialogPanel({ open, position = 'right', children, onClose }: RMDialogPanelProps) {
  const isMobile = useIsMobileViewport();
  const portalRoot = document.getElementById('panel');
  if (!portalRoot) {
    console.error('Missing portal root for dialog');
    return null;
  }

  return createPortal(
    <AnimatePresence>
      {open ? (
        <Root>
          <Backdrop
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.4, ease: 'easeOut' } }}
            exit={{ opacity: 0, transition: { duration: 0.4, ease: 'easeIn' } }}
            onClick={onClose}
          />
          <Panel
            initial={{ ...panelTransitions[position].initial, opacity: 1 }}
            animate={{ ...panelTransitions[position].animate, transition: { duration: 0.4, ease: 'easeOut' } }}
            exit={{ ...panelTransitions[position].exit, transition: { duration: 0.4, ease: 'easeIn' } }}
            data-mobile={isMobile}
          >
            <ToastContainer enableMultiContainer containerId="dialog-panel-toast" position="bottom-center" />
            {children}
          </Panel>
        </Root>
      ) : null}
    </AnimatePresence>,
    portalRoot,
  );
}
